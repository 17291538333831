<template>
  <div v-if="item" class="stocks-products" :key="$route.fullPath">
    <div class="stocks-products__slide-discount">
      <!-- <h1 class="stocks-products__img-title">{{ item.attributes.slogan }}</h1>
      <p class="stocks-products__img-text">{{ item.attributes.label }}</p> -->
      <img
        class="stocks-products__slide-mob"
        :src="
          $helpers.getAbsolutePath(
            item.attributes.backgound_mob.data.attributes.url
          )
        "
        alt="slide image"
      />
      <img
        class="stocks-products__slide-desk"
        :src="
          $helpers.getAbsolutePath(
            item.attributes.backgound_desktop.data.attributes.url
          )
        "
        alt="slide image"
      />
      <div class="container">
        <div class="stocks-products-full__slide-content">
          <h1 class="stocks-products-full__slide-title-discount">
            {{ item.attributes.label }}
          </h1>
          <p class="stocks-products-full__slide-text-discount">
            {{ item.attributes.slogan }}
          </p>
        </div>
      </div>
    </div>

    <div class="container">
      <div class="stocks-products__content">
        <div class="stocks-products__nav">
          <a class="main-page-link"> {{ $t("home") }}/ </a>
          <a class="page-stocks-link"> {{ $t("stock") }}/ </a>
          <a class="page-stocks-product">{{ item.attributes.label }} </a>
        </div>

        <div class="stocks-products__items">
          <div v-if="products" class="header-block">
            <h2 class="title">{{ item.attributes.label }}</h2>
          </div>

          <div :key="products" class="products-week__items">
            <product-card
              v-for="item in products"
              :key="item.id"
              :item="item"
            />
          </div>
          <!-- <button class="stocks-product-more">{{ $t("seeAll") }}</button> -->

          <div class="stocks-products__suggestions">
            <div class="stocks-products__top header-block">
              <h2 class="title">
                {{ $t("interestingOffers") }}
              </h2>
              <locale-router-link to="stocks" class="header-block__link">{{
                $t("showMore")
              }}</locale-router-link>
            </div>
            <div class="stocks-products__suggestions-items">
              <stocks-list v-if="stocks" :items="stocks" />
            </div>
          </div>
        </div>
      </div>
      <!-- container -->
    </div>

    <!-- <section class="banner-vacancy">
      <img
        class="banner-text-img"
        src="@/assets/img/icons/banner-text.svg"
        alt="banner text"
      />
      <div class="container">
        <div class="banner-vacancy__content">
          <div class="banner-vacancy__header">
            <img
              class="banner-wings"
              src="@/assets/img/icons/banner-wings.svg"
              alt="image"
              width="64"
              height="67"
            />
            <h2>
              {{ $t("buildCareer") }}<span> {{ $t("leaveRequest") }}</span>
            </h2>
          </div>
          <button class="btn-gray">{{ $t("submitApplication") }}</button
          ><img
            class="banner-icons"
            src="@/assets/img/icons/banner-icons.svg"
            alt="banner text"
          />
        </div>
      </div>
    </section> -->
  </div>
</template>

<script>
import { getPromos } from "@/api";
import { getProductsByFilters } from "@/api/products";
import { mapActions, mapState } from "vuex";
import { actionTypes } from "@/store/modules/stock";
import { getPromo } from "@/api";

export default {
  name: "PageStocksProducts",
  components: {
    ProductCard: () => import("@/components/products/ProductCard.vue"),
    StocksList: () => import("@/components/stocks/StocksList.vue"),
  },
  data() {
    return {
      stocks: null,
      item: null,
      products: null,
    };
  },
  computed: {
    ...mapState("stockModule", ["saveStockItem"]),
    ...mapState("cityModule", ["selectedCity"]),
  },
  mounted() {},
  created() {
    getPromos(
      { type: "current" },
      { page: 1, pageSize: 3 },
      this.$route.params.id
    ).then((stocks) => {
      this.stocks = stocks.data;
    });
    getPromo(this.$route.params.id, this.selectedCity.id).then((item) => {
      this.item = item.data;
      getProductsByFilters(
        {
          categories: [],
          discountTypes: [this.item?.attributes?.discount?.data?.id],
          shopTypes: [],
        },
        { field: "id", sortType: "asc" },
        { page: 1, pageSize: 1500 },
        this.selectedCity.id
      ).then((response) => {
        this.products = response.data;
      });
    });
  },
  watch: {
    selectedCity: {
      handler() {
        getPromo(this.$route.params.id, this.selectedCity.id).then((item) => {
          this.item = item.data;
          getProductsByFilters(
            {
              categories: [],
              discountTypes: [this.item?.attributes?.discount?.data?.id],
              shopTypes: [],
            },
            { field: "id", sortType: "asc" },
            { page: 1, pageSize: 1500 },
            this.selectedCity.id
          ).then((response) => {
            this.products = response.data;
          });
        });
      },
    },
  },
  methods: {
    ...mapActions("stockModule", {
      saveItem: actionTypes.saveStockItemAction,
    }),
  },
};
</script>

<style scoped lang="sass">
.stocks-products__slide-mob,
.stocks-products__slide-desk
  height: 100%
.stocks-products__slide-mob
  display: none
@media (max-width: 550px)
  .stocks-products__slide-mob
    display: block
  .stocks-products__slide-desk
    display: none
</style>
